import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const RootContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  display: inline-block;
  background-color: ${props => props.theme.incomingMessageBackground};
  border-radius: .3rem;
  width: 17.5%;
`;

const TypingContainer = styled.div`
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const loadingFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

const TypingDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  margin: auto;
  background: ${props => props.theme.incomingMessageFont}80; // hex extended with alpha
  border-radius: 50%;
  animation: ${loadingFade} 1s infinite;
  :nth-child(1) {
    animation-delay: 0s;
  };
  :nth-child(2) {
    animation-delay: 0.2s;
  };
  :nth-child(3) {
    animation-delay: 0.4s;
  };
`;

const Typing: FC = () => (
  <RootContainer>
    <TypingContainer>
      <TypingDot />
      <TypingDot />
      <TypingDot />
    </TypingContainer>
  </RootContainer>
);

export default Typing;