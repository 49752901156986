import { darken } from 'polished';
import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { OnActivateHandler, getButtonProps } from '../utils/buttonHelper';

interface IProps {
  isOpen: boolean;
  unreadMessagesCount: number;
  onClick: OnActivateHandler;
}

const fade = keyframes`
  0% { opacity: 0; transform: scale(1.3); }
  100% { opacity: 1; transform: scale(1); }
`;

const spinAndFade = keyframes`
  0% { transform: rotate(180deg); opacity: 0; }
  100% { transform: rotate(0deg); opacity: 1; }
`;

const scale = keyframes`
  0% { transform: scale(0); }
  100% { transform: rotate(1); }
`;

const rotateAnimationRule = css`
  animation: ${spinAndFade} 0.3s ease;
`;

const fadeAnimationRule = css`
  animation: ${fade} 0.3s ease;
`;

const scaleAnimationRule = css`
  animation: ${scale} 0.3s ease;
`;

const ChatIconContainer = styled.div`
  ${fadeAnimationRule};
  width: 2rem;
  height: 2rem;
`;

const MinimizeIconContainer = styled.div`
  ${rotateAnimationRule};
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledChatButton = styled.div`
  width: 100%;
  height: 100%;  
  border-radius: 9999px;
  background-color: ${props => props.theme.headerBackground};
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  :hover {
    background-color: ${props => darken(0.06, props.theme.headerBackground)};
  }
  transition: background-color 200ms ease;
  ${scaleAnimationRule}
`;

const UnreadMessagesCounter = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background-color: orange;
  border-radius: 99999px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center; 
  vertical-align: middle;
  color: black;
  font-size: 0.8rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
`;

const StyledSvgIcon = styled.svg`
  color: ${props => props.theme.headerFont};
`;

const ChatButton: FC<IProps> = ({ isOpen, unreadMessagesCount, onClick }) => {

  return (
    <StyledChatButton {...getButtonProps(onClick, `${isOpen ? 'Close' : 'Open'} chat widget`)}>
      {isOpen ?
        <MinimizeIconContainer>
          <StyledSvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
          </StyledSvgIcon>
        </MinimizeIconContainer>
        :
        <ChatIconContainer>
          <StyledSvgIcon xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
            <path strokeLinecap='round' strokeLinejoin='round' d='M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z' />
          </StyledSvgIcon>
        </ChatIconContainer>
      }
      {!isOpen && unreadMessagesCount > 0 && <UnreadMessagesCounter>{unreadMessagesCount}</UnreadMessagesCounter>}
    </StyledChatButton>
  );
};

export default ChatButton;