import { nanoid } from '@reduxjs/toolkit';
import { IHeaderUpdate } from '../models/signalR/HeaderUpdate';
import { IConversationStatus, IWidgetSettings } from '../models/signalR/WidgetConfiguration';
import { widgetConnectorUrlAttributeName, widgetRootId, widgetStandaloneModeAttributeName } from './webChatConstants';

const localStorageKey = 'telxl_conversation_id';
const isActiveStorageKeyPrefix = 'telxl_is_active_';
const clientVersion = 'v2';

class WebChatConfig {

  private static _instance?: WebChatConfig;

  public readonly tenantId: string;

  public readonly channelId: string;

  public readonly conversationId: string;

  public readonly hubUrl: string;

  public readonly standaloneMode: boolean;

  private readonly statusUrl: string;

  private readonly _settingsPromise: Promise<IWidgetSettings>;

  private constructor() {
    const widgetRoot = document.getElementById(widgetRootId);

    const urlString = widgetRoot?.getAttribute(widgetConnectorUrlAttributeName);
    if (!urlString) {
      throw new Error('WebChat connector url is missing.');
    }

    const standaloneAttribute = widgetRoot?.getAttribute(widgetStandaloneModeAttributeName);
    this.standaloneMode = standaloneAttribute?.toLowerCase() === 'true';

    const url = new URL(urlString);
    this.tenantId = url.searchParams.get('tenantId') ?? 'missing_tenant_id';
    this.channelId = url.searchParams.get('channelId') ?? 'missing_channel_id';

    const disableHeartbeat = !!url.searchParams.get('disableHeartbeat');
    this.conversationId = this.getOrCreateConversationId();
    this.hubUrl = `${urlString}&conversationId=${this.conversationId}&version=${clientVersion}&disableHeartbeat=${disableHeartbeat}`;

    this.statusUrl = `${url.origin}/webchat/tenants/${this.tenantId}/channels/${this.channelId}/clientsettings/isActiveConversation?conversationId=${this.conversationId}`;

    const settingsUrl = `${url.origin}/webchat/tenants/${this.tenantId}/channels/${this.channelId}/clientsettings?conversationId=${this.conversationId}`;
    this._settingsPromise = fetch(settingsUrl)
      .then(resp => resp.json());
  }

  private getOrCreateConversationId() {
    let conversationId = localStorage.getItem(localStorageKey);
    if (!conversationId) {
      conversationId = nanoid();
      localStorage.setItem(localStorageKey, conversationId);
    }
    return conversationId;
  }

  public getSettings() {
    return this._settingsPromise;
  }

  public markConversationAsActive() {
    localStorage.setItem(isActiveStorageKeyPrefix + this.channelId, 'yes');
  }

  public getConversationStatus() {
    const isActive = !!localStorage.getItem(isActiveStorageKeyPrefix + this.channelId);
    if (!isActive) return { isActive: false, headerInfo: null };

    return fetch(this.statusUrl)
      .then(resp => resp.json())
      .then((status: IConversationStatus) => {
        if (status.isNewConversation)
          localStorage.removeItem(isActiveStorageKeyPrefix + this.channelId);
        else
          this.markConversationAsActive();

        const headerInfo: IHeaderUpdate = {
          avatarUrl: status.avatarUrl,
          displayName: status.displayName,
          headerText: status.title,
        };
        return { isActive: !status.isNewConversation, headerInfo };
      });
  }

  public static getInstance(): WebChatConfig {
    if (!WebChatConfig._instance) {
      WebChatConfig._instance = new WebChatConfig();
    }

    return WebChatConfig._instance;
  }

  public static clearInstance(): void {
    WebChatConfig._instance = undefined;
  }
}

export default WebChatConfig;