import { FC } from 'react';
import styled from 'styled-components';
import ChatMessageItem from './ChatMessageItem';
import StyledMarkdown from './StyledMarkdows';

const TextMessageContainer = styled.div`
  white-space: pre-wrap;
  word-break: normal;
  overflow-wrap: anywhere;
  margin: 0;
  padding-top: .3rem;
	font-size: .85rem;
  line-height: 1rem;
`;

interface IProps {
  timestamp: string;
  text: string;
  isMine: boolean;
  onLoad?(): void;
}

const TextMessage: FC<IProps> = ({ timestamp, text, isMine, onLoad }) => {
  return <ChatMessageItem
    timestamp={timestamp}
    message={<TextMessageContainer><StyledMarkdown onImageLoaded={onLoad}>{text}</StyledMarkdown></TextMessageContainer>}
    isMine={isMine}
  />;
};

export default TextMessage;
