export interface IConversationData {
  workflowData: Record<string, string>;
  customerData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

declare global {
  interface Window {
    getDataForBuzzeasy(): Promise<IConversationData> | undefined;
  }
}

export async function getThirdPartyConversationData(): Promise<IConversationData | undefined> {
  if (!window.getDataForBuzzeasy || typeof window.getDataForBuzzeasy !== 'function')
    return;

  const result = await window.getDataForBuzzeasy();
  if (JSON.stringify(result).length > 150000) {
    // eslint-disable-next-line no-console
    console.error('Buzzeasy conversation data is bigger than 150Kb.');
    return undefined;
  }
  return result;
}