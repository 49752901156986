import { IWidgetAttributes } from '../models/widgetAttributes';
import {
  widgetConnectorUrlAttributeName,
  widgetRootId,
  widgetStandaloneModeAttributeName,
} from './webChatConstants';

export const widgetAttributesParser = (): IWidgetAttributes => {
  const widgetRoot = document.getElementById(widgetRootId);
  const urlString = widgetRoot?.getAttribute(widgetConnectorUrlAttributeName);
  if (!urlString) {
    throw new Error('WebChat connector url is missing.');
  }

  const standaloneAttribute = widgetRoot?.getAttribute(widgetStandaloneModeAttributeName);
  const url = new URL(urlString);

  const widgetUrlObjet: IWidgetAttributes = {
    url: url,
    standaloneMode: standaloneAttribute?.toLowerCase() === 'true',
    disableHeartbeat: url.searchParams.get('disableHeartbeat')?.toLowerCase() == 'true',
    channelId: url.searchParams.get('channelId') ?? 'missing_channel_id',
    tenantId: url.searchParams.get('tenantId') ?? 'missing_tenant_id',
  };

  return widgetUrlObjet;
};
