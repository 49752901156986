import { darken } from 'polished';
import { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import ChatMessagesLayout from '../layouts/ChatMessagesLayout';
import ChatItem from '../presenters/ChatItem';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setIsThirdPartyDataSent } from '../redux/widget/widgetSlice';
import { IChatHub } from '../signalr/useChatHub';
import { IConversationData, getThirdPartyConversationData } from '../utils/integrationHelper';

const ChatContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.background};

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.headerBackground};
    border-radius: 9999px;
    cursor: pointer;

    :hover {
      background-color: ${props => darken(0.05, props.theme.headerBackground)};
    }
  }
`;

interface IProps {
  innerRef: React.RefObject<HTMLDivElement>;
  hub: IChatHub;
}

const ChatMessagesContainer: FC<IProps> = ({ innerRef, hub }) => {

  const dispatch = useAppDispatch();

  const shouldSendThirdPartyData = useAppSelector(state => !state.widget.isThirdPartyDataSent);

  const messages = useAppSelector(state => state.chat.messages);

  const sendTextMessage = useCallback(
    async (payload: string) => {
      let thirdPartyConversationData: IConversationData | undefined;

      if (shouldSendThirdPartyData) {
        thirdPartyConversationData = await getThirdPartyConversationData();
        dispatch(setIsThirdPartyDataSent(true));
      }

      hub.sendCustomerMessage(payload, thirdPartyConversationData);
    },
    [dispatch, hub, shouldSendThirdPartyData],
  );

  const items = useMemo(
    () => messages.map((msg) => (<ChatItem key={msg.key} message={msg} sendSuggestedActionResponse={sendTextMessage} />)),
    [messages, sendTextMessage],
  );

  return (
    <ChatContainer>
      <ChatMessagesLayout>
        {items}
      </ChatMessagesLayout>
      <div
        style={{ float: 'left', clear: 'both' }} // hidden div for scrolling
        ref={innerRef}
        role="none"
      />
    </ChatContainer>
  );
};

export default ChatMessagesContainer;