import styled from 'styled-components';
import WidgetContainer from './containers/WidgetContainer';
import ConfigProvider from './providers/ConfigProvider';

const StaticStyles = styled.div`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  [hidden] { display: 'none!important'; }
`;

function App(): JSX.Element {
  return (
    <StaticStyles>
      <ConfigProvider>
        <WidgetContainer />
      </ConfigProvider>
    </StaticStyles>
  );
}

export default App;