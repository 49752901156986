import { useEffect } from 'react';
import { addProactiveMessage, updateProactiveMessageDisplayState } from '../redux/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface WebChatIntegrationInterface {
  sendProactiveMessage(args: {
    type: 'InviteMessage' | 'PromotionalMessage';
    messageText: string;
    suggestedActions: string[];
  }): void;
}

export default function useHandleIntegration(isInitialized: boolean, channelProactiveMessageTimeout: NodeJS.Timeout | undefined) {
  const dispatch = useAppDispatch();
  const hubState = useAppSelector(s => s.widget.connectionStatus);

  // setup integration
  useEffect(
    () => {
      if (!isInitialized)
        return;

      const integrationInterface: WebChatIntegrationInterface = {
        sendProactiveMessage: (args) => {
          if (hubState === 'initial') {
            if (channelProactiveMessageTimeout)
              clearTimeout(channelProactiveMessageTimeout);

            dispatch(addProactiveMessage(args));
            dispatch(updateProactiveMessageDisplayState(args));
          }
        },
      };

      Object.assign(window, { buzzeasyWebChat: integrationInterface });
      window.dispatchEvent(new Event('buzzeasyWebChat-integrationLoaded'));
    },
    [channelProactiveMessageTimeout, dispatch, hubState, isInitialized],
  );
}