import throttle from 'lodash.throttle';
import { useEffect, useMemo, useState } from 'react';

export interface IThrottleOptions {
  leading?: boolean;
  trailing?: boolean;
}

export default function useThrottledValue<T>(value: T, wait: number, options?: IThrottleOptions): T {
  const [throttledValue, setInternalValue] = useState(value);

  const setThrottledValue = useMemo(
    () => throttle(setInternalValue, wait, options),
    [options, wait],
  );

  useEffect(
    () => { setThrottledValue(value); },
    [setThrottledValue, value],
  );

  return throttledValue;
}