import { darken, lighten, readableColor } from 'polished';
import { MouseEventHandler, ReactElement } from 'react';
import styled from 'styled-components';
import { IAttentionHookMessage, IPromotionalMessage } from '../models/ChatMessage';
import { Color } from '../models/signalR/WidgetConfiguration';
import useSoundNotification from '../hooks/useSoundNotification';
import StyledMarkdown from './StyledMarkdows';
import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';

function linkColor(fontColor: Color): string {
  return readableColor(fontColor, darken(0.10, fontColor), lighten(0.10, fontColor), false);
}

const Container = styled.div`
  margin: 1rem;
  margin-right: 0; // align right side with widget button

  position: relative;

  @keyframes PopupMessage-Container-appear {
    from {
      opacity: 0;
      transform: translateY(4rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: PopupMessage-Container-appear 200ms ease;
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;

  position: absolute;
  right: calc(-1 * 1.25rem / 2);
  top: calc(-1 * 1.25rem / 2);
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;

  display: flex;

  border-radius: 1000000px;
  box-shadow: 0 4px 10px #00000050;

  background-color: ${p => p.theme.incomingMessageBackground};
  color: ${p => p.theme.incomingMessageFont};

  :hover {
    background-color: ${p => p.theme.outgoingMessageBackground};
    color: ${p => p.theme.outgoingMessageFont};
    box-shadow: 0 0 5px ${p => p.theme.headerBackground};
  }

  svg {
    width: 12px;
    height: 12px;
  }

  transition: all 200ms ease;
`;

const MessageContainer = styled.div`
  cursor: pointer;

  max-width: 23rem;
  padding: 1rem;
  display: inline-block;

  white-space: pre-wrap;
  font-size: 1rem;
  color: ${p => p.theme.incomingMessageFont};
  background-color: ${p => p.theme.incomingMessageBackground};

  border-radius: 0.75rem;
  border-bottom-right-radius: 0.125rem;
  box-shadow: 0 4px 10px #00000050;

  a {
    color: ${p => linkColor(p.theme.incomingMessageFont)};
  }
`;

const MessageHeader = styled.div`
  opacity: 0.8;
  font-size: 0.8rem;
  color: ${p => p.theme.incomingMessageFont};
  margin-bottom: 0.125rem;
`;

interface IProps {
  messageTitle?: string;
  message: IPromotionalMessage | IAttentionHookMessage;
  onMessageClick: MouseEventHandler<HTMLDivElement>;
  onDismiss(): void;
}

export default function PopupMessage({ messageTitle = '', message, onMessageClick, onDismiss }: IProps): ReactElement {
  const playSoundNotification = useSoundNotification();

  usePureEffect((_playSoundNotification) => {
    _playSoundNotification();
  }, [], playSoundNotification);

  return (
    <Container>
      <CloseButtonContainer onClick={onDismiss}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor">
          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
      </CloseButtonContainer>
      <MessageContainer onClick={onMessageClick}>
        <MessageHeader>{messageTitle}</MessageHeader>
        <StyledMarkdown>{message.text}</StyledMarkdown>
      </MessageContainer>
    </Container>
  );
}
