type Key =
  | 'ADD_ATTACHMENT'
  | 'ADD_EMOJI'
  | 'CLOSE_CONVERSATION'
  | 'CLOSE_MENU'
  | 'CLOSE_WIDGET'
  | 'CONVERSATION_ENDED'
  | 'DOWNLOAD_TRANSCRIPT'
  | 'EMPTY_FILE_UPLOAD_DISABLED'
  | 'FAILED_TO_UPLOAD_FILE'
  | 'INPUT_MESSAGE_LABEL'
  | 'INPUT_MESSAGE_PLACEHOLDER'
  | 'OPEN_MENU'
  | 'OPEN_WIDGET'
  | 'POLICY_LINK'
  | 'SEND_MESSAGE'
  ;

const en: Record<Key, string> = {
  ADD_ATTACHMENT: 'Add attachment',
  ADD_EMOJI: 'Add emoji',
  CLOSE_CONVERSATION: 'Close conversation',
  CLOSE_MENU: 'Close menu',
  CLOSE_WIDGET: 'Close chat widget',
  CONVERSATION_ENDED: 'Conversation ended',
  DOWNLOAD_TRANSCRIPT: 'Transcript',
  EMPTY_FILE_UPLOAD_DISABLED: 'Empty file upload disabled',
  INPUT_MESSAGE_LABEL: 'Message',
  INPUT_MESSAGE_PLACEHOLDER: 'Type your message',
  FAILED_TO_UPLOAD_FILE: 'Failed to upload file',
  OPEN_MENU: 'Open menu',
  OPEN_WIDGET: 'Open chat widget',
  POLICY_LINK: 'Privacy policy',
  SEND_MESSAGE: 'Send message',
};

const hu: Record<Key, string> = {
  ADD_ATTACHMENT: 'Csatolmány hozzáadása',
  ADD_EMOJI: 'Hangulatjel hozzáadása',
  CLOSE_CONVERSATION: 'Beszélgetés lezárása',
  CLOSE_MENU: 'Menü megnyitása',
  CLOSE_WIDGET: 'Beszélgető felület becsukása',
  CONVERSATION_ENDED: 'Beszélgetés vége',
  DOWNLOAD_TRANSCRIPT: 'Másolat',
  EMPTY_FILE_UPLOAD_DISABLED: 'Üres fájlt nem lehet feltölteni',
  FAILED_TO_UPLOAD_FILE: 'Hiba történt a fájlfeltöltés közben',
  INPUT_MESSAGE_LABEL: 'Üzenet',
  INPUT_MESSAGE_PLACEHOLDER: 'Üzenet beírása',
  OPEN_MENU: 'Menü bezárása',
  OPEN_WIDGET: 'Beszélgető felület kinyitása',
  POLICY_LINK: 'Adatvédelmi irányelvek',
  SEND_MESSAGE: 'Üzenet küldése',
};

const bg: Record<Key, string> = {
  CLOSE_CONVERSATION: 'Прекратете чата',
  CONVERSATION_ENDED: 'Разговорът приключи',
  DOWNLOAD_TRANSCRIPT: 'Препис',
  POLICY_LINK: 'политика за поверителност',
  INPUT_MESSAGE_PLACEHOLDER: 'напишете вашето съобщение',
  FAILED_TO_UPLOAD_FILE: 'Неуспешно качване на файл',
  EMPTY_FILE_UPLOAD_DISABLED: 'Изключено е качването на празни файлове',
  ADD_ATTACHMENT: 'Добавяне на прикачен файл',
  ADD_EMOJI: 'Добавяне на емотикони',
  CLOSE_MENU: 'Затвори менюто',
  CLOSE_WIDGET: 'Затворете уиджета за чат',
  INPUT_MESSAGE_LABEL: 'Съобщение',
  OPEN_MENU: 'Отваряне на менюто',
  OPEN_WIDGET: 'Отворена джаджа за чат',
  SEND_MESSAGE: 'изпращане на съобщение',
};

const sr: Record<Key, string> = {
  CLOSE_CONVERSATION: 'Završi ćaskanje',
  CONVERSATION_ENDED: 'Razgovor je završen',
  DOWNLOAD_TRANSCRIPT: 'Prepis',
  POLICY_LINK: 'Pravila o privatnosti',
  INPUT_MESSAGE_PLACEHOLDER: 'Unesite svoju poruku',
  FAILED_TO_UPLOAD_FILE: 'Otpremanje datoteke nije uspelo',
  EMPTY_FILE_UPLOAD_DISABLED: 'Otpremanje prazne datoteke je onemogućeno',
  ADD_ATTACHMENT: 'Dodaj prilog',
  ADD_EMOJI: 'Dodajte emoji',
  CLOSE_MENU: 'Zatvori meni',
  CLOSE_WIDGET: 'Zatvorite vidžet za ćaskanje',
  INPUT_MESSAGE_LABEL: 'Poruka',
  OPEN_MENU: 'Otvorite meni',
  OPEN_WIDGET: 'Otvorite vidžet za ćaskanje',
  SEND_MESSAGE: 'Pošalji poruku',
};

const he = {
  ADD_ATTACHMENT: 'הוסף קובץ מצורף',
  ADD_EMOJI: 'הוסף אימוג\'י',
  CLOSE_CONVERSATION: 'שיחה קרובה',
  CLOSE_MENU: 'סגור תפריט',
  CLOSE_WIDGET: 'סגור את ווידג\'ט הצ\'אט',
  CONVERSATION_ENDED: 'השיחה הסתיימה',
  DOWNLOAD_TRANSCRIPT: 'תמלול',
  EMPTY_FILE_UPLOAD_DISABLED: 'העלאת קובץ ריק מושבתת',
  INPUT_MESSAGE_LABEL: 'הוֹדָעָה',
  INPUT_MESSAGE_PLACEHOLDER: 'הקלד את ההודעה שלך',
  FAILED_TO_UPLOAD_FILE: 'העלאת הקובץ נכשלה',
  OPEN_MENU: 'פתח את התפריט',
  OPEN_WIDGET: 'פתח את ווידג\'ט הצ\'אט',
  POLICY_LINK: 'מדיניות הפרטיות',
  SEND_MESSAGE: 'לשלוח הודעה',
};

const rtlLanguages = ['HE'];

class TranslationService {
  private _currentLanguage: string;

  private _direction: string;

  constructor() {
    this._currentLanguage = 'EN';
    this._direction = 'ltr';
  }

  setCurrentLanguage(language: string): void {
    this._currentLanguage = language;
    this._direction = rtlLanguages.includes(language) ? 'rtl' : 'ltr';
  }

  overrideLanguageDirection(dir: string): void {
    this._direction = dir;
  }

  getTranslation(key: Key): string {
    switch (this._currentLanguage) {
      case 'EN':
        return en[key];
      case 'HU':
        return hu[key];
      case 'SR':
        return sr[key];
      case 'BG':
        return bg[key];
      case 'HE':
        return he[key];
      default:
        return en[key];
    }
  }

  getDirection(): string {
    return this._direction;
  }
}
const service = new TranslationService();
export default service;