import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { IWidgetSettings } from '../models/signalR/WidgetConfiguration';
import { isConversationActive, markConversationAsActive, removeConversation } from '../utils/conversationHelper';
import { widgetAttributesParser } from '../utils/widgetAttributesParser';
import { ConversationStatusResponse, useGetSettingsQuery, useGetConversationStatusQuery } from '../redux/apis/widgetApi';
import { getOrCreateItem } from '../utils/localStorageHelpers';
import { nanoid } from 'nanoid';

interface IConfigContext {
  conversationId: string;
  tenantId: string;
  channelId: string;
  chatHubUrl: string;
  trackingHubUrl: string;
  standaloneMode: boolean;
  settings: IWidgetSettings;
  conversationStatus: ConversationStatusResponse;
}

interface IProps {
  children?: React.ReactNode;
}

const conversationIdStorageKey = 'buzzeasy_conversation_id';
const CLIENT_VERSION = 'v2';
const ConfigContext = createContext<IConfigContext | undefined>(undefined);

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfigContext can only be used in a ConfigProvider tree');
  }

  return context;
};

const ConfigProvider: FC<IProps> = ({ children }) => {
  const { url, disableHeartbeat, standaloneMode, tenantId, channelId } = useMemo(() => widgetAttributesParser(), []);
  const conversationId = useMemo(() => getOrCreateItem(conversationIdStorageKey, nanoid()), []);
  const chatHubUrl = `${url.href}&conversationId=${conversationId}&version=${CLIENT_VERSION}&disableHeartbeat=${disableHeartbeat}`;
  const trackingHubUrl = `${url.origin}/customer-tracking/customer_interactions?tenantId=${tenantId}&channelId=${channelId}`;

  const { data: settings } = useGetSettingsQuery(conversationId);
  const { data: conversationStatusResponse } = useGetConversationStatusQuery(conversationId, {
    skip: !isConversationActive(channelId),
  });

  const [conversationStatus, setConversationStatus] = useState<ConversationStatusResponse | undefined>(undefined);

  useEffect(
    () => {
      if (conversationStatusResponse) {
        if (conversationStatusResponse.isActive) {
          markConversationAsActive(channelId);
        }
        else {
          removeConversation(channelId);
        }
      }

      if (isConversationActive(channelId)) {
        setConversationStatus(conversationStatusResponse);
      }
      else {
        setConversationStatus({ isActive: false, headerInfo: null });
      }
    },
    [channelId, conversationStatusResponse],
  );

  if (!settings || !conversationStatus) {
    return <></>;
  }

  return (
    <ConfigContext.Provider
      value={{
        tenantId,
        conversationId,
        channelId,
        chatHubUrl,
        trackingHubUrl,
        standaloneMode,
        settings: settings,
        conversationStatus: conversationStatus,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
