import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  isOpen: boolean,
  isStandaloneView: boolean;
  chatBox: ReactNode;
  button: ReactNode;
  promotionalMessage: ReactNode;
}

const NormalWidgetRoot = styled.div<{ isOpen: boolean }>`
  pointer-events: all;
  position: fixed;
  right: ${props => !(props.theme.isFullScreenMode && props.isOpen) ? '1rem' : '0'};
  bottom: ${props => !(props.theme.isFullScreenMode && props.isOpen) ? '1rem' : '0'};
  z-index: 99999;
`;

const WidgetButton = styled.div<{ isOpen: boolean }>`
  display: ${props => props.theme.isFullScreenMode ? (props.isOpen ? 'none' : 'block') : 'block'};
  width: 4rem;
  height: 4rem;
  float: right;
  transition: transform 167ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
  :hover {
    transform: scale(1.1);
    transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
  }
`;

const TransitionBox = styled.div<{ isOpen: boolean }>`
  --tw-shadow: ${props => !props.theme.isFullScreenMode && '0 25px 50px -12px rgb(0 0 0 / 0.25)'};
  --tw-shadow-colored: ${props => !props.theme.isFullScreenMode && '0 25px 50px -12px var(--tw-shadow-color)'} ;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius:  ${props => !props.theme.isFullScreenMode && '0.8rem'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  width: ${props => props.isOpen ? (props.theme.isFullScreenMode ? '100vw' : '23rem') : '0'};
  height: ${props => props.isOpen ? (props.theme.isFullScreenMode ? '100dvh' : '34rem') : '0'};
  overflow: hidden;
  transform-origin: right bottom;
  transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;
  transition-duration: 200ms, 200ms, 200ms, 300ms, 83ms;
  transition-timing-function: ease, ease, ease, cubic-bezier(0, 1.2, 1, 1), ease-out;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-property: width, height, max-height, transform, opacity;
  transform: ${props => !props.isOpen && 'scale(0)'};
  margin: ${props => !props.theme.isFullScreenMode && '0rem 0rem 1rem 0rem'};
`;

const WidgetBox = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

const WidgetLayout: FC<IProps> = ({ isOpen, isStandaloneView, chatBox, button, promotionalMessage }) => {

  if (isStandaloneView)
    return <WidgetBox>{chatBox}</WidgetBox>;

  return (
    <NormalWidgetRoot isOpen={isOpen}>
      <TransitionBox isOpen={isOpen}>
        <WidgetBox>{chatBox}</WidgetBox>
      </TransitionBox>
      {promotionalMessage}
      <WidgetButton isOpen={isOpen}>{button}</WidgetButton>
    </NormalWidgetRoot>
  );
};

export default WidgetLayout;