import { FC } from 'react';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';
import styled from 'styled-components';

const CustomMarkdown = styled(Markdown)`
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: none;
  }
`;

interface IProps {
  children: string | null | undefined;
  onImageLoaded?(): void;
}



const StyledMarkdown: FC<IProps> = ({ children, onImageLoaded }) => {
  // Not necessary, but looks better then the loading animation
  const handleImageLoaded = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    (event.target as HTMLImageElement).style.display = 'block';
    onImageLoaded?.call(onImageLoaded);
  };

  return (
    <CustomMarkdown
      remarkPlugins={[gfm]}
      components={{
        img(props) {
          const { ...rest } = props;
          return <img {...rest} onLoad={handleImageLoaded} />;
        },
      }}
    >
      {children}
    </CustomMarkdown>
  );
};

export default StyledMarkdown;
