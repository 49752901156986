import { configureStore } from '@reduxjs/toolkit';
import chatReducer from '../redux/chat/chatSlice';
import widgetReducer from '../redux/widget/widgetSlice';
import { widgetApi } from './apis/widgetApi';

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    widget: widgetReducer,
    [widgetApi.reducerPath]: widgetApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(widgetApi.middleware),
});

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
