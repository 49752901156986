import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ThemeProvider } from 'styled-components';
import useHandleIntegration from '../hooks/useHandleIntegration';
import useMediaQuery from '../hooks/useMediaQuery';
import ChatBoxLayout from '../layouts/ChatBoxLayout';
import WidgetLayout from '../layouts/WidgetLayout';
import { IHistoryItem } from '../models/content-manger/HistoryItem';
import { IHeaderUpdate } from '../models/signalR/HeaderUpdate';
import { IMessageToCustomer } from '../models/signalR/ToCustomerMessage';
import { ITypingIndicator } from '../models/signalR/TypingIndicator';
import { ITheme, defaultColorTheme } from '../models/signalR/WidgetConfiguration';
import ChatButton from '../presenters/ChatButton';
import { messageInputId } from '../presenters/InputBox';
import NotificationBar from '../presenters/Notification';
import PromotionalMessage from '../presenters/PromotionalMessage';
import { addConversationHistory, addEndConversation, addMessage, addProactiveMessage, clearUnreadMessageCountAndPromotionalMessage, setIsConversationEnded, showAgentTypingIndicator, updateProactiveMessageDisplayState } from '../redux/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setHeaderData, setIsThirdPartyDataSent, setMenuState, setNotificationMessage, setupWidget } from '../redux/widget/widgetSlice';
import useChatHub from '../signalr/useChatHub';
import TranslationService from '../translations/translationService';
import { OnActivateHandler } from '../utils/buttonHelper';
import WebChatConfig from '../utils/webChatConfig';
import { widgetRootId } from '../utils/webChatConstants';
import ChatMessagesContainer from './ChatMessagesContainer';
import HeaderContainer from './HeaderContainer';
import InputContainer from './InputContainer';

const isOpenStorageKey = 'telxl_chat_is_open';

const WidgetContainer: FC = () => {
  const config = useMemo(() => WebChatConfig.getInstance(), []);
  const defaultBodyOverflow = useMemo(() => document.body.style.overflow, []);
  const defaultBodyPosition = useMemo(() => document.body.style.position, []);

  const dispatch = useAppDispatch();

  const [theme, setTheme] = useState<ITheme>(defaultColorTheme);
  const [isOpen, setIsOpen] = useState(!!sessionStorage.getItem(isOpenStorageKey));
  const [isInitialized, setInitialized] = useState(false);
  const [isFullScreenMode, setFullScreenMode] = useState(window.innerWidth < 400 || window.innerHeight < 640);

  const connectionStatus = useAppSelector(state => state.widget.connectionStatus);
  const unreadMessageCount = useAppSelector(state => state.chat.unreadMessageCount);
  const notificationMessage = useAppSelector(state => state.widget.notificationMessage);
  const messages = useAppSelector(state => state.chat.messages);
  const isConversationEnded = useAppSelector(state => state.chat.isConversationEnded);
  const showTypingIndicator = useAppSelector(state => state.chat.showTypingIndicator);
  const promotionalMessage = useAppSelector(state => state.chat.promotionalMessage);
  const promotionalMessageTitle = useAppSelector(state => state.widget.headerData.subtitle);

  const proactiveMessageTimeoutRef = useRef<NodeJS.Timeout>();

  const handleWithViewportChanges = useCallback(
    (isMatch: boolean) => {
      setFullScreenMode(isMatch);
      setTheme(prevTheme => ({
        ...prevTheme,
        isFullScreenMode: isMatch,
      }));
    },
    [],
  );

  useMediaQuery('(max-width: 400px), (max-height: 640px)', handleWithViewportChanges);

  const handleMessageReceived = useCallback(
    (message: IMessageToCustomer) => {
      dispatch(addMessage(message));
    }, [dispatch],
  );

  const handleHeaderUpdate = useCallback(
    (headerData: IHeaderUpdate) => {
      dispatch(setHeaderData(headerData));
    }, [dispatch],
  );

  const handleOnConversationEnded = useCallback(
    () => {
      dispatch(setIsConversationEnded(true));
      dispatch(addEndConversation());
      dispatch(setIsThirdPartyDataSent(false));
      dispatch(setMenuState(false));
    }, [dispatch],
  );

  const handleOnTyping = useCallback(
    (typingIndicator: ITypingIndicator) => {
      dispatch(showAgentTypingIndicator(typingIndicator));
    },
    [dispatch],
  );

  const chatHub = useChatHub(
    {
      onMessageReceived: handleMessageReceived,
      onHeaderUpdated: handleHeaderUpdate,
      onConversationEnded: handleOnConversationEnded,
      onTyping: handleOnTyping,
    },
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(
    () => setTimeout(
      () => {
        const current = scrollRef.current;
        if (current) {
          scrollIntoView(current, { behavior: 'smooth', block: 'nearest' });
        }
      },
      100,
    ),
    [],
  );

  const openCloseChatWidget: OnActivateHandler = useCallback(
    e => {
      // toggle and persist isOpen
      setIsOpen(current => {
        const newIsOpen = !current;
        if (newIsOpen)
          sessionStorage.setItem(isOpenStorageKey, 'yes');
        else
          sessionStorage.removeItem(isOpenStorageKey);
        return newIsOpen;
      });

      dispatch(clearUnreadMessageCountAndPromotionalMessage());
      scrollToBottom();

      if (proactiveMessageTimeoutRef.current) {
        clearTimeout(proactiveMessageTimeoutRef.current);
        proactiveMessageTimeoutRef.current = undefined;
      }

      e.stopPropagation();
    },
    [dispatch, scrollToBottom],
  );

  const closeHeaderMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
    () => {
      dispatch(setMenuState(false));
    },
    [dispatch],
  );

  const downloadHistory = useCallback(
    async (threadId: string, cmUrl: string, cmApiKey: string) => {
      try {
        const response = await fetch(`${cmUrl}v2/conversationhistory/webchat?tenant_id=${config.tenantId}&thread_id=${threadId}`, {
          method: 'GET',
          headers: {
            'API-KEY': cmApiKey,
          },
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        dispatch(addConversationHistory(data as IHistoryItem[]));
        scrollToBottom();
      }
      catch {
        return dispatch(addConversationHistory([]));
      }
    },
    [config.tenantId, dispatch, scrollToBottom],
  );

  useHandleIntegration(isInitialized, proactiveMessageTimeoutRef.current);

  useEffect(
    () => {
      async function initialize() {
        const settings = await config.getSettings();
        settings.theme.isFullScreenMode = isFullScreenMode;
        settings.theme.isMobileDevice = isMobile;
        setTheme(settings.theme);
        const {
          contentManagerApiKey,
          contentManagerUrl,
          threadId,
          proactiveMessage,
          language,
        } = settings;

        await downloadHistory(threadId, contentManagerUrl, contentManagerApiKey);

        dispatch(setupWidget(settings));

        const { isActive, headerInfo } = await config.getConversationStatus();
        if (headerInfo) {
          dispatch(setHeaderData(headerInfo));
        }

        if (isActive) {
          dispatch(setIsThirdPartyDataSent(true));
          chatHub.start();
        }
        else if (proactiveMessage) {
          dispatch(addProactiveMessage(proactiveMessage));

          if (proactiveMessage.delayInSeconds) {
            proactiveMessageTimeoutRef.current = setTimeout(() => dispatch(updateProactiveMessageDisplayState(proactiveMessage)), proactiveMessage.delayInSeconds * 1000);
          }
          else {
            dispatch(updateProactiveMessageDisplayState(proactiveMessage));
          }

          scrollToBottom();
        }

        TranslationService.setCurrentLanguage(language);

        const widgetRoot = document.getElementById(widgetRootId);
        if (widgetRoot) {
          if (widgetRoot.dir)
            TranslationService.overrideLanguageDirection(widgetRoot.dir);
          else
            widgetRoot.dir = TranslationService.getDirection();
        }
      }

      if (!isInitialized) {
        initialize();
        setInitialized(true);
      }

    },
    [isInitialized, downloadHistory, dispatch, chatHub, scrollToBottom, config, isFullScreenMode],
  );

  useEffect(
    () => {
      const resetSettings = async () => {
        const settings = await config.getSettings();
        dispatch(setupWidget(settings));
      };

      if (isInitialized && connectionStatus === 'disconnected') {
        resetSettings();
      }
    },
    [connectionStatus, isInitialized, dispatch, config],
  );

  useEffect(
    () => {
      if (notificationMessage) {
        const timeout = setTimeout(() => dispatch(setNotificationMessage(undefined)), 3000);
        return () => clearTimeout(timeout);
      }
    },
    [notificationMessage, dispatch],
  );

  useEffect(
    () => {
      config.markConversationAsActive();
      dispatch(setIsConversationEnded(false));
      scrollToBottom();
    },
    [messages, scrollToBottom, dispatch, config],
  );

  useEffect(
    () => {
      if (showTypingIndicator) {
        scrollToBottom();
      }
    },
    [showTypingIndicator, dispatch, scrollToBottom],
  );

  useEffect(
    () => {
      if (isConversationEnded) {
        chatHub.disconnect();
        scrollToBottom();
      }
    },
    [isConversationEnded, chatHub, scrollToBottom, dispatch],
  );

  useEffect(
    () => {
      if (isOpen && !isMobile)
        document.getElementById(messageInputId)?.focus();
    },
    [isOpen],
  );

  useEffect(
    () => {
      if (isFullScreenMode) {
        document.body.style.overflow = isOpen ? 'hidden' : defaultBodyOverflow;
        document.body.style.position = isOpen ? 'fixed' : defaultBodyPosition;
      }
    },
    [isOpen, isFullScreenMode, defaultBodyOverflow, defaultBodyPosition],
  );

  if (!isInitialized)
    return null;

  return (
    <ThemeProvider theme={theme}>
      <WidgetLayout
        isOpen={isOpen}
        isStandaloneView={config.standaloneMode}
        chatBox={
          <ChatBoxLayout
            isFullscreenView={isFullScreenMode || config.standaloneMode}
            onClick={closeHeaderMenu}
            header={
              <HeaderContainer
                displayCloseButton={isFullScreenMode && !config.standaloneMode}
                hub={chatHub}
                onMinimize={() => setIsOpen(false)}
              />
            }
            notificationBar={
              (connectionStatus === 'reconnecting' || connectionStatus === 'disconnected' || notificationMessage) &&
              <NotificationBar notificationMessage={notificationMessage} connectionStatus={connectionStatus} />
            }
            messages={
              <ChatMessagesContainer
                innerRef={scrollRef}
                hub={chatHub}
              />
            }
            input={<InputContainer hub={chatHub} />}
          />
        }
        button={
          <ChatButton
            isOpen={isOpen}
            unreadMessagesCount={unreadMessageCount}
            onClick={openCloseChatWidget}
          />
        }
        promotionalMessage={
          (!isOpen && promotionalMessage) &&
          <PromotionalMessage
            messageTitle={promotionalMessageTitle}
            promotionalMessage={promotionalMessage}
            onMessageClick={openCloseChatWidget}
            onDismiss={() => dispatch(clearUnreadMessageCountAndPromotionalMessage())}
          />
        }
      />
    </ThemeProvider>
  );
};

export default WidgetContainer;
