import { HTMLAttributes } from 'react';

type Element = HTMLElement | SVGSVGElement;

type OnClickEventHandler<T extends Element> = NonNullable<HTMLAttributes<T>['onClick']>;
type OnClickEvent<T extends Element> = Parameters<OnClickEventHandler<T>>[0];

type OnKeyDownEventHandler<T extends Element> = NonNullable<HTMLAttributes<T>['onKeyDown']>;
type OnKeyDownEvent<T extends Element> = Parameters<OnKeyDownEventHandler<T>>[0];

export type OnActivateHandler<T extends Element = Element> = (e: OnClickEvent<T> | OnKeyDownEvent<T>) => void;

type ElementButtonProps<T extends Element> = Required<Pick<HTMLAttributes<T>, 'role' | 'aria-label' | 'tabIndex' | 'onClick' | 'onKeyDown'>> & Pick<HTMLAttributes<T>, 'title'>;

function handleOnKeyDown<T extends Element>(e: OnKeyDownEvent<T>, onActivate: OnActivateHandler<T>): void {
  if (
    (e.key === 'Enter' || e.code === 'Space') &&
    !e.ctrlKey &&
    !e.shiftKey &&
    !e.altKey &&
    !e.metaKey
  ) {
    onActivate(e);
  }
}

export function getButtonProps<T extends Element = Element>(onActivate: OnActivateHandler<T>, ariaLabel: string, title?: boolean | string): ElementButtonProps<T> {

  const titleText = typeof title === 'string'
    ? title
    : (typeof title === 'boolean' && title)
      ? ariaLabel
      : undefined;

  return {
    role: 'button',
    'aria-label': ariaLabel,
    tabIndex: 0,
    onClick: onActivate,
    onKeyDown: (e) => handleOnKeyDown(e, onActivate),
    title: titleText,
  };
}