import { FC } from 'react';
import { Anchorme } from 'react-anchorme';
import styled from 'styled-components';
import ChatMessageItem from './ChatMessageItem';

const TextMessageContainer = styled.p`
  white-space: pre-wrap;
  word-break: normal;
  overflow-wrap: anywhere;
  margin: 0;
  padding-top: .3rem;
	font-size: .85rem;
  line-height: 1rem;
`;

interface IProps {
  timestamp: string,
  text: string,
  isMine: boolean,
}

const TextMessage: FC<IProps> = ({ timestamp, text, isMine }) => {
  return <ChatMessageItem
    timestamp={timestamp}
    message={<TextMessageContainer><Anchorme linkComponent={p => <a {...p} />} target="_blank" rel="noreferrer noopener">{text}</Anchorme></TextMessageContainer>}
    isMine={isMine}
  />;
};

export default TextMessage;