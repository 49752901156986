import JSZip from 'jszip';
import { IChatMessage, MessageType } from '../models/ChatMessage';

class TranscriptService {

  public create = async (channelName: string, conversationId: string, chatMessages: IChatMessage[]) => {
    const messagesWithAttachment = chatMessages.filter(x => x.type === MessageType.Attachment);
    const messagesBlob = this.createMessagesBlob(channelName, conversationId, chatMessages);

    if (messagesWithAttachment.length == 0) {
      return {
        isZip: false,
        blob: messagesBlob,
      };
    }

    const zipBlob = await this.zipAttachments(messagesBlob, messagesWithAttachment);

    return {
      isZip: true,
      blob: zipBlob,
    };
  };

  private createMessagesBlob = (channelName: string, conversationId: string, chatMessages: IChatMessage[]) => {
    const title = `Downloaded at: ${new Date().toLocaleString()}\r\n`;
    const conversationInfo = `Conversation Id: ${conversationId}\r\n\r\n`;

    const orderedChatMessages = [...chatMessages]
      .filter(x => x.type === MessageType.Text || x.type === MessageType.Attachment)
      .sort((a, b) => a.date.localeCompare(b.date));

    const formattedMessages = orderedChatMessages.map(x => {
      const date = new Date(x.date).toLocaleString();
      const line = `[${date}] ${x.from !== 'Customer' ? channelName : x.from}\r\n`;

      if (x.type === MessageType.Text)
        return line + `${x.text}\r\n\r\n`;

      if (x.type === MessageType.Attachment)
        return line + `${x.attachment.name}\r\n\r\n`;

      return line;
    });

    return new Blob([title, conversationInfo, ...formattedMessages], { type: 'text/plain;charset=utf-8' });

  };

  private zipAttachments = async (messagesBlob: Blob, messagesWithAttachment: IChatMessage[]) => {
    const zip = new JSZip();
    zip.file('transcript.txt', messagesBlob);
    const attachmentsFolder = zip.folder('attachments');

    for (const messageWithAttachment of messagesWithAttachment) {
      if (messageWithAttachment.type === MessageType.Attachment) {
        const response = await fetch(messageWithAttachment.attachment.contentUrl);
        const attachmentBlob = await response.blob();
        attachmentsFolder?.file(messageWithAttachment.attachment.name, attachmentBlob);
      }
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    return zipBlob;
  };
}
export const transcriptService = new TranscriptService();