import { FC } from 'react';
import { IChatMessage, MessageType, ParticipantType } from '../models/ChatMessage';
import Divider from './Divider';
import GenericAttachment from './GenericAttachment';
import ImageAttachment from './ImageAttachment';
import MessageHistoryLoading from './MessageHistoryLoading';
import SuggestedActions from './SuggestedActions';
import TextMessage from './TextMessage';
import Typing from './Typing';

interface IProps {
  message: IChatMessage
  sendSuggestedActionResponse(payload: string): Promise<void>;
}

const ChatItem: FC<IProps> = ({ message, sendSuggestedActionResponse }) => {

  function generateTimestamp(): string {
    return new Date(message.date).toLocaleTimeString();
  }

  function isMine(): boolean {
    return message.from === ParticipantType.Customer;
  }

  switch (message.type) {
    case MessageType.Divider:
      return <Divider text={message.text} />;
    case MessageType.Typing:
      return <Typing />;
    case MessageType.Loading:
      return <MessageHistoryLoading />;
    case MessageType.SuggestedAction:
      return <SuggestedActions
        disabled={message.disabled}
        actions={message.suggestedActions}
        onSelect={sendSuggestedActionResponse}
      />;
    case MessageType.Text:
      return <TextMessage
        timestamp={generateTimestamp()}
        text={message.text}
        isMine={isMine()}
      />;
    case MessageType.Attachment:
      if (message.attachment.contentType === 'image/png'
        || message.attachment.contentType === 'image/jpeg'
        || message.attachment.contentType === 'image/gif') {

        return <ImageAttachment
          timestamp={generateTimestamp()}
          contentUrl={message.attachment.contentUrl}
          isMine={isMine()} />;
      }
      return <GenericAttachment
        timestamp={generateTimestamp()}
        contentUrl={message.attachment.contentUrl}
        attachmentName={message.attachment.name}
        isMine={isMine()} />;

    default:
      return <div>Error</div>;
  }
};

export default ChatItem;