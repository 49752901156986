import { FC, useCallback, useEffect, useRef, useState } from 'react';
import useThrottledValue, { IThrottleOptions } from '../hooks/useThrottledValue';
import InputBox, { messageInputId } from '../presenters/InputBox';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setIsThirdPartyDataSent, setNotificationMessage } from '../redux/widget/widgetSlice';
import { IChatHub } from '../signalr/useChatHub';
import TranslationService from '../translations/translationService';
import { OnActivateHandler } from '../utils/buttonHelper';
import { IConversationData, getThirdPartyConversationData } from '../utils/integrationHelper';

interface IProps {
  hub: IChatHub;
}

const throttleOpts: IThrottleOptions = { leading: true, trailing: false };

const InputContainer: FC<IProps> = ({ hub }) => {
  const dispatch = useAppDispatch();

  const isUploadEnabled = useAppSelector(state => state.widget.isUploadEnabled);

  const shouldSendWorkflowData = useAppSelector(state => !state.widget.isThirdPartyDataSent);
  const [cmUrl, cmApiKey] = useAppSelector(state => [state.widget.contentManagerUrl, state.widget.contentManagerApiKey]);

  const [inputValue, setInputValue] = useState('');
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const throttledValue = useThrottledValue(inputValue, 3000, throttleOpts);

  const connectionStatus = useAppSelector(state => state.widget.connectionStatus);

  useEffect(
    () => {
      if (connectionStatus === 'connected' && throttledValue) {
        hub.sendCustomerTyping();
      }
    },
    [connectionStatus, hub, throttledValue],
  );

  const sendTextMessage = useCallback(
    async () => {
      if (inputValue !== null && inputValue.trim() !== '') {
        let thirdPartyConversationData: IConversationData | undefined = undefined;

        if (shouldSendWorkflowData) {
          thirdPartyConversationData = await getThirdPartyConversationData();
          dispatch(setIsThirdPartyDataSent(true));
        }
        
        hub.sendCustomerMessage(inputValue, thirdPartyConversationData);
        setInputValue('');
        document.getElementById(messageInputId)?.focus();
      }
    },
    [dispatch, hub, inputValue, shouldSendWorkflowData],
  );

  const sendAttachment = useCallback(
    async (url: string, name: string, type: string) => {
      if (url && type) {
        let thirdPartyConversationData: IConversationData | undefined;

        if (shouldSendWorkflowData) {
          thirdPartyConversationData = await getThirdPartyConversationData();
          dispatch(setIsThirdPartyDataSent(true));
        }

        hub.sendCustomerAttachment({ name: name, contentUrl: url, contentType: type }, thirdPartyConversationData);
      }
    },
    [dispatch, hub, shouldSendWorkflowData],
  );


  const openFileSelector: OnActivateHandler = useCallback(
    (e) => {
      inputFileRef?.current?.click();
      e.stopPropagation();
    },
    [],
  );


  const uploadAttachment: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const files = inputFileRef?.current?.files;

      if (files && files.length > 0) {
        setIsUploadInProgress(true);
        const formData = new FormData();
        const file = files[0];
        if (file.size > 2e+7 || file.size == 0) {
          setIsUploadInProgress(false);
          dispatch(setNotificationMessage(file.size == 0 ?
            TranslationService.getTranslation('EMPTY_FILE_UPLOAD_DISABLED')
            : 'Max file size limit is 20mb',
          ));

          e.target.value = '';
          e.target.files = null;

          return;
        }
        formData.set('', file, file.name);

        fetch(`${cmUrl}attachments/webchat/upload`, {
          method: 'POST',
          headers: {
            'API-KEY': cmApiKey,
          },
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              if (response.status == 500) {
                const messageTitle = TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE');
                dispatch(setNotificationMessage(messageTitle));
              }
              else {
                response.text().then(t => {
                  const messageTitle = TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE');
                  if (t) {

                    dispatch(setNotificationMessage(`${messageTitle}: ${t}`));
                  }
                  else {
                    dispatch(setNotificationMessage(`${messageTitle}: ${response.statusText}`));
                  }
                });

              }
              setIsUploadInProgress(false);
            }
            else {
              return response;
            }
          })
          .then(
            response => response?.text().then(
              data => {
                sendAttachment(data, file.name, file.type);
                setIsUploadInProgress(false);
              },
            ),
          )
          .catch(
            () => {
              setIsUploadInProgress(false);
              dispatch(setNotificationMessage(TranslationService.getTranslation('FAILED_TO_UPLOAD_FILE')));
            },
          );
      }

      e.target.value = '';
      e.target.files = null;

      e.stopPropagation();
    },
    [cmUrl, cmApiKey, dispatch, sendAttachment],
  );

  return (<InputBox
    isUploadEnabled={isUploadEnabled}
    isUploadInProgress={isUploadInProgress}
    inputValue={inputValue}
    onChange={setInputValue}
    onSubmit={sendTextMessage}
    inputFileRef={inputFileRef}
    uploadAttachment={openFileSelector}
    inputFileAddedHandler={uploadAttachment} />);
};

export default InputContainer;