export type Color = `#${string}`;

export interface ITheme {
  background: Color;
  actionButton: Color;
  headerBackground: Color;
  headerFont: Color;
  outgoingMessageBackground: Color;
  outgoingMessageFont: Color;
  incomingMessageBackground: Color;
  incomingMessageFont: Color;
  suggestedActionBackground: Color;
  suggestedActionFont: Color;
  isFullScreenMode: boolean;
  isMobileDevice: boolean;
}

export interface IWidgetSettings {
  contentManagerUrl: string;
  contentManagerApiKey: string;
  isUploadEnabled: boolean;
  threadId: string;
  theme: ITheme;
  language: string;
  defaultAvatarUrl: string;
  defaultDisplayName: string;
  defaultTitle: string;
  proactiveMessage?: IProactiveMessage;
  disableTranscriptDownload: boolean;
  privacyPolicyUrl?: string
}

export interface IProactiveMessage {
  type: 'InviteMessage' | 'WelcomeMessage' | 'PromotionalMessage';
  messageText: string;
  suggestedActions: string[];
  delayInSeconds?: number;
}

export interface IConversationStatus {
  isNewConversation: boolean;
  title: string;
  displayName: string;
  avatarUrl: string;
}

export const defaultColorTheme: ITheme = {
  background: '#f3f2f1',
  actionButton: '#326ec2',
  headerBackground: '#326ec2',
  headerFont: '#ffffff',
  outgoingMessageBackground: '#326ec2',
  outgoingMessageFont: '#f3f2f1',
  incomingMessageBackground: '#ffffff',
  incomingMessageFont: '#201f1e',
  suggestedActionBackground: '#ffffff',
  suggestedActionFont: '#323130',
  isFullScreenMode: false,
  isMobileDevice: false,
};