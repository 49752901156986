import { getItem, setItem } from '@buzzeasy/shared-frontend-utilities';

const isActiveStorageKeyPrefix = 'buzzeasy_is_active_';

export const markConversationAsActive = (channelId: string) => {
  setItem(isActiveStorageKeyPrefix + channelId, 'yes');
};

export const isConversationActive = (channelId: string) => {
  return !!getItem(isActiveStorageKeyPrefix + channelId, null);
};

export const removeConversation = (channelId: string) => {
  localStorage.removeItem(isActiveStorageKeyPrefix + channelId);
};
