import { useCallback } from 'react';
import { useAppSelector } from '../redux/hooks';

const notificationSound = new Audio('https://cdn.geomant.cloud/buzzeasy/webchat_newmessage.mp3');

const waitForInteraction = () => {
  notificationSound.play();
  document.removeEventListener('click', waitForInteraction);
};

const useSoundNotification = () => {
  const isNotificationSoundOn = useAppSelector(state => state.widget.notificationSound);

  const playNotification = useCallback(
    (shouldPlayNotification = true) => {
      if (isNotificationSoundOn && shouldPlayNotification) {
        notificationSound.play().catch(err => {
          // This is needed as browsers don't let you play sound without first interacting with the document
          // eslint-disable-next-line no-console
          console.error('Notification could not be played:', err.message);
          document.addEventListener('click', waitForInteraction);
        });
      }
    },
    [isNotificationSoundOn],
  );

  return playNotification;
};

export default useSoundNotification;
