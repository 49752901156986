import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IHeaderUpdate } from '../models/signalR/HeaderUpdate';
import { IMessageToCustomer } from '../models/signalR/ToCustomerMessage';
import { useAppDispatch } from '../redux/hooks';
import { setConnectionStatus } from '../redux/widget/widgetSlice';
import { IConversationData } from '../utils/integrationHelper';
import WebChatConfig from '../utils/webChatConfig';
import { ITypingIndicator } from '../models/signalR/TypingIndicator';

interface Method {
  name: string;
  handler(data: unknown): void;
}

interface IHandlers {
  onMessageReceived?(message: IMessageToCustomer): void;
  onHeaderUpdated?(headerData: IHeaderUpdate): void;
  onTyping(typingIndicator: ITypingIndicator): void;
  onConversationEnded?(): void;
}

export interface IChatHub {
  start(): Promise<unknown>;
  sendCustomerMessage(text: string, thirdPartyConversationData?: IConversationData): void;
  sendCustomerAttachment(attachment: { name: string, contentUrl: string, contentType: string }, thirdPartyConversationData?: IConversationData): void;
  sendCustomerTyping(): void;
  endConversation(): void;
  disconnect(): void;
}

const useChatHub = ({
  onMessageReceived,
  onHeaderUpdated,
  onTyping,
  onConversationEnded,
}: IHandlers): IChatHub => {
  // NOTE: connection mutates internally
  const [connection, setConnection] = useState<HubConnection>();

  const [messageQueue, setMessageQueue] = useState<{ method: string; arg?: unknown }[]>([]);

  useEffect(
    () => {
      if (connection?.state === HubConnectionState.Connected && messageQueue.length > 0) {
        messageQueue.forEach(m => connection.send(m.method, m.arg));
        setMessageQueue([]);
      }
    },
    [connection, connection?.state, messageQueue],
  );

  useEffect(
    () => {
      const { hubUrl } = WebChatConfig.getInstance();
      const newConnection = new HubConnectionBuilder()
        .withUrl(hubUrl)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);

      return () => {
        if (newConnection.state === HubConnectionState.Connected)
          newConnection.stop();
      };
    },
    [],
  );

  const dispatch = useAppDispatch();

  const handlers = useMemo<Method[]>(
    () => {
      return [
        { name: 'ReceiveMessage', handler: onMessageReceived },
        { name: 'UpdateHeader', handler: onHeaderUpdated },
        { name: 'ShowTypingIndicator', handler: onTyping },
        { name: 'EndConversation', handler: onConversationEnded },
      ] as Method[];
    },
    [onMessageReceived, onHeaderUpdated, onTyping, onConversationEnded],
  );

  useEffect(
    () => {
      handlers.forEach(m => connection?.on(m.name, m.handler));
      connection?.onclose(() => dispatch(setConnectionStatus('disconnected')));
      connection?.onreconnecting(() => { dispatch(setConnectionStatus('reconnecting')); });
      connection?.onreconnected(() => { dispatch(setConnectionStatus('connected')); });
    },
    [connection, dispatch, handlers],
  );

  const start = useCallback(
    async () => {
      if (connection?.state === HubConnectionState.Disconnected) {
        dispatch(setConnectionStatus('connecting'));
        try {
          await connection.start();
          dispatch(setConnectionStatus('connected'));
        }
        catch {
          dispatch(setConnectionStatus('disconnected'));
        }
      }
      return connection;
    },
    [connection, dispatch],
  );

  const send = useCallback(
    (method: string, arg?: unknown) => {
      if (connection?.state === HubConnectionState.Connected) {
        if (arg) // This is if-else NOT pointless. This is actually required for correct send behavior.
          connection.send(method, arg);
        else
          connection.send(method);
      }
      else {
        start();
        setMessageQueue(curr => [...curr, { method, arg }]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection, connection?.state, start],
  );

  const disconnect = useCallback(
    () => {
      connection?.stop();
    },
    [connection],
  );

  return useMemo<IChatHub>(
    () => ({
      start,
      sendCustomerMessage: (messageBody, data) => send('SendCustomerMessage', { messageBody, workflowData: data?.workflowData, customerInfo: data?.customerData }),
      sendCustomerAttachment: (attachment, data) => send('SendCustomerMessage', { attachments: [attachment], workflowData: data?.workflowData, customerInfo: data?.customerData }),
      sendCustomerTyping: () => send('CustomerIsTyping'),
      endConversation: () => send('CloseConversation'),
      disconnect,
    }),
    [send, start, disconnect],
  );
};

export default useChatHub;