import data from '@emoji-mart/data';
import EmojiPicker from '@emoji-mart/react';
import { getLuminance } from 'polished';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { default as TranslationService, default as translationService } from '../translations/translationService';
import { OnActivateHandler, getButtonProps } from '../utils/buttonHelper';

export const messageInputId = 'buzzeasy-message-input';

interface IProps {
  isUploadEnabled: boolean;
  isUploadInProgress: boolean;
  inputValue: string;
  inputFileRef: React.RefObject<HTMLInputElement>;
  onSubmit(): void
  onChange(text: string): void
  inputFileAddedHandler: React.ChangeEventHandler<HTMLInputElement>;
  uploadAttachment: OnActivateHandler;
}

interface InputProps {
  isUploadEnabled: boolean;
}

interface CursorProps {
  disabled: boolean;
}

interface Emoji {
  native: string
}

const InputBoxContainer = styled.div`
  padding: 0.4rem;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  background-color: ${props => props.theme.incomingMessageBackground};
`;

const StyledAttachmentButton = styled.button<CursorProps>`
  flex: 1 1;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const StyledEmojiPickerContainer = styled.div` //need this for the ref
  flex: 1 1;
  padding-left: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSendButton = styled.button`
  flex: 1 1;
  border: 0;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0rem;
  padding-right: 0.3rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? `${props.theme.actionButton}80` : props.theme.actionButton};

  transition: all 0.2s ease-in-out;
`;

const StyledInputField = styled.input<InputProps>`
  width: ${props => props.isUploadEnabled ? '85%' : '93%'};
  color: ${props => props.theme.incomingMessageFont};
  background-color: ${props => props.theme.incomingMessageBackground};
  font-size: 0.9rem;
  border: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  &:focus {
      outline: none;
  };
  ::placeholder{
      color: ${props => props.theme.incomingMessageFont};
      opacity: 80%;
  }
`;

const AttachmentIcon = styled.svg`
  width: 1.1rem;
  height: 1.1rem;
  color: ${props => props.theme.actionButton};
  margin-bottom: .2rem;
`;

const SendIcon = styled.svg<{ $direction: string }>`
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: .2rem;
  transform: rotate(${p => p.$direction === 'rtl' ? '-90deg' : '90deg'});
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation = styled.svg`
  width: 1.1rem;
  height: 1.1rem;
  color: ${props => props.theme.actionButton};
  margin-bottom: .2rem;
  animation: ${spin} 1s linear infinite;
`;

const StyledEmojiMenuContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
`;

const StyledEmojiPickerButton = styled.button`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  padding: 0;
  cursor: pointer;
`;

const EmojiIcon = styled.svg`
  width: 1.1rem;
  height: 1.1rem;
  color: ${props => props.theme.actionButton};
  margin-bottom: .2rem;
`;

const InputBox: FC<IProps> = ({ isUploadEnabled, isUploadInProgress, inputValue, onChange, onSubmit, uploadAttachment, inputFileRef, inputFileAddedHandler }) => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(
    () => {
      const clickOutsideHandler = (e: MouseEvent) => {
        if (pickerRef.current && !pickerRef.current.contains(e.target as Node))
          setIsEmojiPickerVisible(false);
      };

      document.addEventListener('mouseup', clickOutsideHandler);

      return () => {
        document.removeEventListener('mouseup', clickOutsideHandler);
      };
    },
    [],
  );

  const onEmojiSelect = useCallback(
    (emoji: Emoji) => {
      if (textInputRef.current) {
        textInputRef.current.focus();
        const caretPosition = textInputRef.current.selectionStart ?? inputValue.length;
        const newInputValue = inputValue.substring(0, caretPosition) + emoji.native + inputValue.substring(caretPosition, inputValue.length);
        onChange(newInputValue);
      }
    },
    [inputValue, onChange],
  );

  const submitOnEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        onSubmit();
        setIsEmojiPickerVisible(false);
      }
    },
    [onSubmit],
  );

  return (<InputBoxContainer>
    {isUploadEnabled &&
      <StyledAttachmentButton disabled={isUploadInProgress} {...getButtonProps(uploadAttachment, translationService.getTranslation('ADD_ATTACHMENT'), true)}>
        {
          isUploadInProgress ?
            <LoadingAnimation xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle style={{ opacity: '.25' }} cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path style={{ opacity: '.75' }} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </LoadingAnimation>
            :
            <AttachmentIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 22" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
            </AttachmentIcon>
        }
        <input type='file' id='file' ref={inputFileRef} onChange={inputFileAddedHandler} style={{ display: 'none' }} role="none" />
      </StyledAttachmentButton>
    }
    <StyledEmojiPickerContainer ref={pickerRef}>
      <StyledEmojiPickerButton {...getButtonProps(() => setIsEmojiPickerVisible(!isEmojiPickerVisible), translationService.getTranslation('ADD_EMOJI'), true)}>
        <EmojiIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          {isEmojiPickerVisible ?
            <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM208.4 208c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm128 32c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
            :
            <path fill="currentColor" d="M256 352C293.2 352 319.2 334.5 334.4 318.1C343.3 308.4 358.5 307.7 368.3 316.7C378 325.7 378.6 340.9 369.6 350.6C347.7 374.5 309.7 400 256 400C202.3 400 164.3 374.5 142.4 350.6C133.4 340.9 133.1 325.7 143.7 316.7C153.5 307.7 168.7 308.4 177.6 318.1C192.8 334.5 218.8 352 256 352zM208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208zM304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
          }
        </EmojiIcon>
      </StyledEmojiPickerButton >
      {isEmojiPickerVisible &&
        <StyledEmojiMenuContainer>
          <EmojiPicker
            data={data}
            perLine='8'
            maxFrequentRows='1'
            emojiSize='20'
            theme={getLuminance(theme.background) > 0.5 ? 'light' : 'dark'}
            emojiButtonSize='29'
            previewPosition='none'
            onEmojiSelect={onEmojiSelect}
          />
        </StyledEmojiMenuContainer>
      }
    </StyledEmojiPickerContainer>
    <StyledInputField
      id={messageInputId}
      isUploadEnabled={isUploadEnabled}
      className='outline-none'
      placeholder={TranslationService.getTranslation('INPUT_MESSAGE_PLACEHOLDER')}
      value={inputValue}
      ref={textInputRef}
      onKeyDown={submitOnEnter}
      onChange={(e) => onChange(e.target.value)}
      onClick={() => setIsEmojiPickerVisible(false)}
      maxLength={9000}
      aria-label={translationService.getTranslation('INPUT_MESSAGE_LABEL')}
    />
    <StyledSendButton disabled={!inputValue} {...getButtonProps(onSubmit, translationService.getTranslation('SEND_MESSAGE'), true)}>
      <SendIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.8} $direction={TranslationService.getDirection()}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
      </SendIcon>
    </StyledSendButton>
  </InputBoxContainer>
  );
};

export default InputBox;