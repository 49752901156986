/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable quotes */
import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import {
  IAttachmentMessage,
  IAttentionHookMessage,
  IChatMessage,
  IPromotionalMessage,
  MessageType,
  ParticipantType,
} from "../../models/ChatMessage";
import { IHistoryItem } from "../../models/content-manger/HistoryItem";
import { IMessageToCustomer } from "../../models/signalR/ToCustomerMessage";
import { ITypingIndicator } from "../../models/signalR/TypingIndicator";
import { IProactiveMessage } from "../../models/signalR/WidgetConfiguration";
import TranslationService from "../../translations/translationService";

export interface ChatState {
  messages: IChatMessage[];
  unreadMessageCount: number;
  isConversationEnded: boolean;
  popupMessage: IPromotionalMessage | IAttentionHookMessage | null;
}

const initialState: ChatState = {
  messages: [
    {
      key: nanoid(),
      type: MessageType.Loading,
      from: ParticipantType.System,
      date: new Date().toISOString(),
    },
  ],
  unreadMessageCount: 0,
  isConversationEnded: false,
  popupMessage: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addProactiveMessage(state, { payload }: PayloadAction<IProactiveMessage>) {
      state.messages.forEach((msg) => {
        if (msg.type === MessageType.SuggestedAction) msg.disabled = true;
      });
      state.messages.push({
        key: nanoid(),
        type: MessageType.Text,
        text: payload.messageText,
        date: new Date().toISOString(),
        from: ParticipantType.Bot,
      });
      if (payload.suggestedActions?.length > 0) {
        state.messages.push({
          key: nanoid(),
          type: MessageType.SuggestedAction,
          date: new Date().toISOString(),
          from: ParticipantType.Bot,
          disabled: false,
          suggestedActions: payload.suggestedActions,
        });
      }
    },
    updateProactiveMessageDisplayState(
      state,
      { payload }: PayloadAction<IProactiveMessage>
    ) {
      if (payload.type === "InviteMessage") {
        state.unreadMessageCount = 1;
      }

      if (payload.type === "PromotionalMessage") {
        state.popupMessage = {
          text: payload.messageText,
          date: new Date().toISOString(),
          suggestedActions: payload.suggestedActions ?? [],
        };
      }
    },
    addMessage(state, { payload }: PayloadAction<IMessageToCustomer>) {
      if (payload.participantType !== ParticipantType.Customer) {
        state.messages.forEach((msg) => {
          if (msg.type === MessageType.SuggestedAction) msg.disabled = true;
        });
      }
      if (payload.messageBody) {
        state.messages = [
          ...state.messages.filter((y) => y.type != MessageType.Typing),
          {
            key: nanoid(),
            type: MessageType.Text,
            text: payload.messageBody,
            date: new Date().toISOString(),
            from: payload.participantType,
          },
        ];
      }
      if (payload.attachments) {
        const attachments: IAttachmentMessage[] = payload.attachments.map(
          (attachment) => ({
            key: nanoid(),
            type: MessageType.Attachment,
            date: new Date().toISOString(),
            from: payload.participantType,
            attachment,
          })
        );
        state.messages = [...state.messages, ...attachments];
      }
      if (payload.suggestedActions?.actions) {
        state.messages = [
          ...state.messages,
          {
            key: nanoid(),
            type: MessageType.SuggestedAction,
            date: new Date().toISOString(),
            from: payload.participantType,
            disabled: false,
            suggestedActions: payload.suggestedActions?.actions
              ?.filter((x) => x.text || x.title)
              .map((x) => x.text ?? x.title ?? ""),
          },
        ];
      }

      state.unreadMessageCount += 1;
    },
    addConversationHistory(state, { payload }: PayloadAction<IHistoryItem[]>) {
      const sortedHistory = payload
        .map((msg) => ({
          ...msg,
          createdAt: new Date(msg.createdAt).toISOString(),
        }))
        .sort((msg1, msg2) =>
          new Date(msg1.createdAt) >= new Date(msg2.createdAt) ? 1 : -1
        );

      const botMessages = sortedHistory.filter(
        (msg) => msg.from === ParticipantType.Bot
      );
      const lastBotMessage = botMessages[botMessages.length - 1];

      let items: IChatMessage[] = [];

      sortedHistory.forEach((message) => {
        if (message.messageBody) {
          items.push({
            key: nanoid(),
            type: MessageType.Text,
            date: message.createdAt,
            from: message.from,
            text: message.messageBody,
          });
        }
        if (message.attachments && message.attachments.length > 0) {
          const attachments: IAttachmentMessage[] = message.attachments.map(
            (attachment) => ({
              key: nanoid(),
              type: MessageType.Attachment,
              date: message.createdAt,
              from: message.from,
              attachment: {
                name: attachment.name,
                contentType: attachment.contentType,
                contentUrl: attachment.contentUrl,
              },
            })
          );

          items = items.concat(attachments);
        }

        if (message.suggestedActions?.actions?.length) {
          items.push({
            key: nanoid(),
            type: MessageType.SuggestedAction,
            date: message.createdAt,
            from: message.from,
            disabled: message !== lastBotMessage,
            suggestedActions: message.suggestedActions?.actions.map(
              (x) => x.text ?? x.title ?? ""
            ),
          });
        }
      });

      state.messages = items;
    },
    addEndConversation(state) {
      state.messages.forEach((msg) => {
        if (msg.type === MessageType.SuggestedAction) msg.disabled = true;
      });
      state.messages = [
        ...state.messages.filter((y) => y.type != MessageType.Typing),
        {
          key: nanoid(),
          type: MessageType.Divider,
          text: TranslationService.getTranslation("CONVERSATION_ENDED") ?? "",
          date: new Date().toISOString(),
          from: ParticipantType.System,
        },
      ];
    },
    showAgentTypingIndicator(
      state,
      { payload }: PayloadAction<ITypingIndicator>
    ) {
      const nonCustomerMessages = state.messages.filter(
        (x) =>
          x.from !== ParticipantType.Customer && x.type !== MessageType.Typing
      );

      if (nonCustomerMessages.length > 0) {
        const latestNonCustomerMessage =
          nonCustomerMessages[nonCustomerMessages.length - 1];
        const typingIndicatorCreatedAtDate = new Date(payload.createdAt);
        const latestNonCustomerMessageDate = new Date(
          latestNonCustomerMessage.date
        );

        if (
          typingIndicatorCreatedAtDate.getTime() <
          latestNonCustomerMessageDate.getTime()
        ) {
          return;
        }
      }

      state.messages = [
        ...state.messages.filter((y) => y.type != MessageType.Typing),
        {
          key: nanoid(),
          type: MessageType.Typing,
          date: new Date().toISOString(),
          from: ParticipantType.System,
        },
      ];
    },
    addAttentionHookMessage(
      state,
      { payload }: PayloadAction<{ text: string }>
    ) {
      state.popupMessage = {
        text: payload.text,
        date: new Date().toISOString(),
      };
    },
    clearPopupMessage(state) {
      state.popupMessage = null;
    },
    clearUnreadMessageCounter(state) {
      state.unreadMessageCount = 0;
    },

    setIsConversationEnded(state, { payload }: PayloadAction<boolean>) {
      state.isConversationEnded = payload;
    },
    removeTypingIndicator(state) {
      state.messages = [
        ...state.messages.filter((y) => y.type != MessageType.Typing),
      ];
    },
  },
});

export const {
  addProactiveMessage,
  updateProactiveMessageDisplayState,
  addMessage,
  addConversationHistory,
  addEndConversation,
  showAgentTypingIndicator,
  clearPopupMessage,
  clearUnreadMessageCounter,
  setIsConversationEnded,
  removeTypingIndicator,
  addAttentionHookMessage,
} = chatSlice.actions;
export default chatSlice.reducer;
