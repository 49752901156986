import { configureStore } from '@reduxjs/toolkit';
import chatReducer from '../redux/chat/chatSlice';
import widgetReducer from '../redux/widget/widgetSlice';

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    widget: widgetReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;