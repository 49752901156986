import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import WebChatConfig from './utils/webChatConfig';
import { widgetRootId } from './utils/webChatConstants';

let reactRoot: ReactDOM.Root;

function renderReactApp(el: HTMLElement) {
  reactRoot = ReactDOM.createRoot(el);

  reactRoot.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}

const rootElement = document.getElementById(widgetRootId) as HTMLElement;

if (!rootElement) {
  // eslint-disable-next-line no-console
  console.warn(`Element with id=${widgetRootId} not found. Started to observe body children ...`);
}
else {
  renderReactApp(rootElement);
}

const mutationObserver = new MutationObserver((mutationList) => {
  const mutations = Array.from(mutationList);

  const removedWidgetRoot = mutations
    .flatMap(mutation => Array.from(mutation.removedNodes) as HTMLElement[])
    .find(removedElement => removedElement.id === widgetRootId);

  if (removedWidgetRoot) {
    // eslint-disable-next-line no-console
    console.log(`Element with id=${widgetRootId} has been removed. Buzzeasy chat widget unmounting...`);
    reactRoot?.unmount();
    WebChatConfig.clearInstance();

  }

  const addedWidgetRoot = mutations
    .flatMap(mutation => Array.from(mutation.addedNodes) as HTMLElement[])
    .find(addedElement => addedElement.id === widgetRootId);

  if (addedWidgetRoot) {
    // eslint-disable-next-line no-console
    console.log(`Element with id=${widgetRootId} detected. Buzzeasy chat widget initializing...`);
    renderReactApp(addedWidgetRoot);
  }
});
mutationObserver.observe(document.body, { childList: true });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();