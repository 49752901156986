import { FC } from 'react';
import styled from 'styled-components';
import ChatMessageItem from './ChatMessageItem';

const ImageContainer = styled.img`
  padding-top: .3rem;
  max-width: 100%;
`;

interface IProps {
  timestamp: string,
  contentUrl: string,
  isMine: boolean,
}

const ImageAttachment: FC<IProps> = ({ timestamp, contentUrl, isMine }) => {
  return (
    <ChatMessageItem
      timestamp={timestamp}
      message={<a href={contentUrl} target='_blank' rel='noopener noreferrer'><ImageContainer src={contentUrl} /></a>}
      isMine={isMine} />);
};

export default ImageAttachment;