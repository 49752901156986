import saveAs from 'file-saver';
import { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Header from '../presenters/Header';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setMenuState, setNotificationMessage, toggleNotificationSound } from '../redux/widget/widgetSlice';
import { transcriptService } from '../services/transcriptService';
import { IChatHub } from '../signalr/useChatHub';
import { default as TranslationService, default as translationService } from '../translations/translationService';
import { OnActivateHandler, getButtonProps } from '../utils/buttonHelper';
import { useConfigContext } from '../providers/ConfigProvider';
import { notificationSoundLocalStorage } from '../utils/webChatConstants';
import { setItem } from '@buzzeasy/shared-frontend-utilities';

interface IProps {
  displayCloseButton: boolean;
  hub: IChatHub;
  onMinimize(): void
}

const RootContainer = styled.div`
  position: relative;
  display: flex;
`;

const StyledMinimizeIcon = styled.svg`
  width: 2rem;
  height: 1rem;
  margin-right: 0.2rem;
  cursor: pointer;
  color: ${props => props.theme.headerFont};
`;

const StyledMenuIcon = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: ${props => props.theme.headerFont};
`;

const MenuContainer = styled.div<{ $direction: string }>`
  width: 13rem;
  position: absolute;
  top: 28px;
  right: 0px;
  z-index: 10;
  background-color: ${props => props.theme.incomingMessageBackground};
  border-radius: 0.25rem;
  overflow: hidden;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  user-select: none;

  :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
    --tw-divide-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-divide-opacity));
  }

  ${p => p.$direction === 'rtl' && css`
    left: 0;
    right: unset;
  `}
`;

const MenuList = styled.ul`
  font-size: 0.875rem/* 14px */;
  line-height: 1.25rem/* 20px */;
  margin: unset;
  margin-block: unset;
  padding-inline-start: unset;
  color: ${props => props.theme.incomingMessageFont};
`;

const MenuItem = styled.li`
  cursor: pointer;
  text-align: left;
  padding: 0.5rem 0.75rem;

  display: flex;
  align-items: center;
  gap: 1rem;

  :hover {
    background-color: ${props => props.theme.incomingMessageFont}20;
  }
`;

const Icon = styled.svg`
  height: 1.25rem;
  width: 1.25rem;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.incomingMessageFont};
`;

const ActionText = styled.div`
  color: ${props => props.theme.incomingMessageFont};
  display: block;
`;

const HeaderContainer: FC<IProps> = ({ displayCloseButton, hub, onMinimize }) => {
  const config = useConfigContext();
  const dispatch = useAppDispatch();

  const headerData = useAppSelector(state => state.widget.headerData);
  const messages = useAppSelector(state => state.chat.messages);
  const isMenuOpen = useAppSelector(state => state.widget.isMenuOpen);
  const disableTranscriptDownload = useAppSelector(state => state.widget.disableTranscriptDownload);
  const privacyPolicyUrl = useAppSelector(state => state.widget.privacyPolicyUrl);
  const isNotificationSoundOn = useAppSelector(state => state.widget.notificationSound);

  const openCloseMenu: OnActivateHandler = useCallback(
    (e) => {
      dispatch(setMenuState(!isMenuOpen));
      e.stopPropagation();
    },
    [dispatch, isMenuOpen],
  );

  const downloadTranscript = useCallback(
    () => {
      dispatch(setMenuState(false));
      dispatch(setNotificationMessage(TranslationService.getTranslation('PREPARING_TRANSCRIPT')));
      transcriptService.create(headerData.title, config.conversationId, messages)
        .then(({ isZip, blob }) => saveAs(blob, isZip ? 'transcript.zip' : 'transcript.txt'))
        .catch(() => dispatch(setNotificationMessage(TranslationService.getTranslation('FAILED_TO_DOWNLOAD_TRANSCRIPT'))));
    },
    [config.conversationId, dispatch, headerData.title, messages],
  );

  const onToggleNotificationSound = () => {
    setItem(notificationSoundLocalStorage, !isNotificationSoundOn);
    dispatch(toggleNotificationSound());
  };

  return <Header title={headerData.title} subtitle={headerData.subtitle} avatarUrl={headerData.avatarUrl}>
    <RootContainer>
      {displayCloseButton && <div {...getButtonProps(onMinimize, translationService.getTranslation('CLOSE_WIDGET'), true)}>
        <StyledMinimizeIcon xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
          <path d="M32 416c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H32z" />
        </StyledMinimizeIcon>
      </div>}
      <div {...getButtonProps(openCloseMenu, translationService.getTranslation(isMenuOpen ? 'CLOSE_MENU' : 'OPEN_MENU'), true)} aria-haspopup="menu" aria-expanded={isMenuOpen}>
        <StyledMenuIcon xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24">
          <path d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
        </StyledMenuIcon>
      </div>
      {
        isMenuOpen &&
        <MenuContainer role="menu" $direction={TranslationService.getDirection()}>
          <MenuList>
            <MenuItem {...getButtonProps(hub.endConversation, TranslationService.getTranslation('CLOSE_CONVERSATION'))} role="menuitem">
              <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </Icon>
              <ActionText>{TranslationService.getTranslation('CLOSE_CONVERSATION')}</ActionText>
            </MenuItem>
            {disableTranscriptDownload === false &&
              <MenuItem {...getButtonProps(downloadTranscript, TranslationService.getTranslation('DOWNLOAD_TRANSCRIPT'))} role="menuitem">
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                </Icon>
                <ActionText>{TranslationService.getTranslation('DOWNLOAD_TRANSCRIPT')}</ActionText>
              </MenuItem>
            }
            <MenuItem {...getButtonProps(onToggleNotificationSound, TranslationService.getTranslation('TOGGLE_NOTIFICATION_SOUND'))}>
              <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="currentColor">
                {isNotificationSoundOn ?
                  <path fillRule="evenodd" d="M192 64C86 64 0 150 0 256S86 448 192 448H384c106 0 192-86 192-192s-86-192-192-192H192zm192 96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" clipRule="evenodd" />
                  :
                  <path fillRule="evenodd" d="M384 128c70.7 0 128 57.3 128 128s-57.3 128-128 128H192c-70.7 0-128-57.3-128-128s57.3-128 128-128H384zM576 256c0-106-86-192-192-192H192C86 64 0 150 0 256S86 448 192 448H384c106 0 192-86 192-192zM192 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" clipRule="evenodd" />
                }
              </Icon>
              <ActionText>{TranslationService.getTranslation('TOGGLE_NOTIFICATION_SOUND')}</ActionText>
            </MenuItem>
            <Link href={privacyPolicyUrl ? privacyPolicyUrl : 'https://www.telxl.com/privacy-policy'} target="_blank" rel="noreferrer noopener">
              <MenuItem>
                <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </Icon>
                <span>{TranslationService.getTranslation('POLICY_LINK')}</span>
              </MenuItem>
            </Link>
          </MenuList >
        </MenuContainer >
      }
    </RootContainer >
  </Header >;
};

export default HeaderContainer;